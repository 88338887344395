<template>
  <ion-page>
    <ion-header>
      <shop-header />
    </ion-header>
    <ion-content>
      <div v-if="!loading">
        <div>
          <i
            class="mdi mdi-close-circle-outline text--primary"
            style="font-size: 85px;"
          />
        </div>
        <div class="text--white">
          {{ $t('Updating payment method has been canceled') }}
        </div>
        <div class="ion-padding">
          <ion-button @click="goBack">
            <ion-label>{{ $t('Go back to eshop') }}</ion-label>
          </ion-button>
        </div>
      </div>
      <div v-else>
        <ion-spinner
          color="light"
          name="crescent"
        /><br>
        {{ $t('Checking status') }}
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import shopHeader from '../Headers/ShopHeaderComponent.vue'

export default {
  name: 'EShopCancel',
  components: {
    shopHeader
  },
  data () {
    return {
      loading: false,
      sessionId: ''
    }
  },
  computed: {
  },
  //   watch: {
  //     '$route.params.id': function (id) {
  //       if (this.$route.name === 'eShopCancel') {
  //         if (id) {
  //           this.loading = true
  //           setTimeout(() => {
  //             this.checkPaymentStatus(id)
  //           }, 2000)
  //         }
  //       }
  //     }
  //   },
  //   created () {
  //     this.sessionId = this.$route.params.id
  //   },
  //   mounted () {
  //     this.loading = true
  //     setTimeout(() => {
  //       this.checkPaymentStatus(this.sessionId)
  //     }, 2000)
  //   },
  methods: {
    // checkPaymentStatus (sessionId) {
    //   this.$store.dispatch('checkPaymentStatus', { sessionId, type: 'cancelled' })
    //     .then(res => {
    //       this.loading = false
    //       console.log(res.data)
    //     })
    //     .catch((err) => {
    //       if (err) {
    //         this.loading = false
    //       }
    //     })
    // },
    goBack () {
      this.loading = false
      this.sessionId = ''
      this.$router.push('/e-shop')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
.search {
  --background: #2c3036;
  text-align: left;
  --color: white;
  --icon-color: white;
  max-width: 400px;
}

.items{
  padding:0 8px;
  max-width: 520px;
  .product-img {
    max-width: 140px;
    margin-top: 5px;
  }
}
.h-65 {
    max-width: 150px;
    height: 54px;
    color: white;
    font-size: 16px;
    background-color: #35393f9c;
    text-align: center;
    border-radius: 5px;
    line-height: 50px;
}
.h-65-active {
  border: 1px solid #ffd4a4;
  border-radius: 5px;
      background: #3f424a;
    color: #ffd4a4;
}
.detail-picture {
  margin: 0 auto;
  max-width: 350px;
}
.product-description {
transition: all 0.5s ease;
}
.showMore {
  height: 230px;
  transition: height 0.15s ease-out;
  -webkit-transition: height 0.15s ease-out;
}
.showLess {
  height: 130px;
  transition: height 0.15s ease-in;
  -webkit-transition: height 0.15s ease-in;
}
</style>
